// BR
/*
const formatValue = (value: number): string =>
  Intl.NumberFormat(
    'pt-BR',
    { style: 'currency', currency: 'BRL' }
  ).format(
    value,
  );
 */
 // US
 const formatValue = (value: number): string =>
  Intl.NumberFormat(
    'en-US',
    { style: 'currency', currency: 'USD' }
  ).format(
    value,
  );




export default formatValue;
